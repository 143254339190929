import React from 'react';

interface MoneyProps {
  cents: number;
}

export function Money({ cents }: MoneyProps) {
  const absoluteAmountInDollars = Math.abs(cents / 100);
  const dollars = Math.floor(absoluteAmountInDollars);
  const decimals = Math.floor(absoluteAmountInDollars - dollars);

  return (
    <span>
      {cents < 0 ? '-' : null}${dollars}.{decimals < 10 && '0'}
      {decimals}
    </span>
  );
}
