import React, { useEffect, useRef } from 'react';
import { Conversation, Message, User, HousingApplication } from '@/types';
import classNames from 'classnames';

interface ConversationPreviewProps {
  conversation: Conversation;
  onClick: (conversation: Conversation) => void;
}

export function ConversationPreview({
  conversation,
  onClick,
}: ConversationPreviewProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const unread = conversation.last_read_at < conversation.last_message_at;
  const timestamp = new Date(conversation.last_message_at*1000).toLocaleString();

  return (
    <div
      ref={containerRef}
      role="button"
      className="card p-2 mb-2 user-select-none"
      onClick={() => onClick(conversation)}
    >
      <div className="text-truncate">
        <strong>{conversation.subject_title}</strong>
        {conversation.archived && (
          <span className="badge bg-dark ms-2">archived</span>
        )}
        {unread && (
          <div className="badge bg-primary ms-2">unread</div>
        )}
        <div className='small float-sm-end text-muted'>{timestamp}</div>
      </div>
      {conversation.attachment_title?.length && (
        <div className="text-truncate">
          <small>Attached: {conversation.attachment_title}</small>
        </div>
      )}

      <div className="text-truncate">{conversation.last_message?.text}</div>
    </div>
  );
}
