import { useCallback, useRef, useEffect, useMemo, useState } from 'react';
import { Conversation, ConversationId } from '@/types';

interface UseSelectedConversationProps {
  initialConversationId: ConversationId | undefined;
}

export function useSelectedConversation({
  initialConversationId,
}: UseSelectedConversationProps) {
  const [selectedConversationId, setSelectedConversationId] = useState(initialConversationId);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      // on initial mount, don't run the turbolinks code
      isInitialMount.current = false;
    } else {
      const url = new URL(window.location);
      if (selectedConversationId) {
        url.searchParams.set('conversation_id', selectedConversationId.toString());
        url.searchParams.delete('subject_id');
        url.searchParams.delete('subject_type');
      } else {
        url.searchParams.delete('conversation_id');
      } 
      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid())
    }
  }, [selectedConversationId]);

  return { selectedConversationId, setSelectedConversationId };
}
