import React from 'react';
import { HousingApplication, User } from '@/types';
import { DateDisplay } from '@/components/Date/Display';

interface HousingApplicationProps {
  housingApplication: HousingApplication;
  currentUser: User;
}

export function HousingApplicationCard({ housingApplication, currentUser }: HousingApplicationProps) {

  const numAddonFields = [
    "school_status",
    "school_name",
    "major",
    "credit_status",
    "pet",
    "smoker",
    "vehicle",
    "languages",
    "allergies",
  ].filter((attr) => housingApplication[attr])
   .length

  //{I18n.activerecord.attributes.housing_application[attr]}
  //{housingApplication[attr]}

  return (
    <div className='card'>
      <div className='card-body'>
        <div className='card-title'>
          <b>
            {numAddonFields > 0 && 'Housing questionnaire'}
            {numAddonFields === 0 && 'Message'}
            {' '}from{' '}
            {housingApplication.first_name}{' '}{housingApplication.last_name}
          </b>
        </div>
        <div className='mb-2'>
          {housingApplication.email}<br />
          {housingApplication.phone}
          {housingApplication.move_in_date && (
            <span>
              <br />
              Move in date: {housingApplication.move_in_date}
            </span>
          )}
        </div>
        {numAddonFields > 0 && (
          <div className='card-text'>
            <a href={housingApplication.route_path} className='btn btn-primary'>
              View {numAddonFields} more fields
            </a>
          </div>
        )}
        <small className='card-text text-muted'>
          Submitted on <DateDisplay dateString={housingApplication.created_at*1000} />
        </small>
      </div>
    </div>
  );
}
