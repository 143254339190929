import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Id, Conversation, Message, User } from '@/types';
import { MessageBalloon } from '@/components/Message/Balloon';
import { Listing } from '@/components/Listing';
import { HousingApplicationCard } from '@/components/HousingApplicationCard';
import { RoommateCard } from '@/components/RoommateCard';
import { ClassifiedCard } from '@/components/ClassifiedCard';
import { ConversationChatInput } from './Input';
import axios from 'axios';

interface ConversationChatProps {
  conversationId: number | undefined;
  getConversationEndpoint: string;
  sendMessageEndpoint: string;
  currentUser: User;
  onArchive: (text: string) => void;
  onUnarchive: (text: string) => void;
  onBack?: () => void;
}

export function ConversationChat({
  conversationId,
  currentUser,
  onBack,
  onArchive,
  onUnarchive,
  getConversationEndpoint,
  sendMessageEndpoint,
}: ConversationChatProps) {

  const [conversation, setConversation] = useState(null);

  useEffect(() => {
    const load = async () => {
      setConversation(null);
      const response = await fetch(getConversationEndpoint.replace(':id', conversationId));

      if (response.ok) {
        const conv = await response.json();
        const firstUnreadMessage = conv.messages.find((message) => message.created_at > conv.last_read_at);
        if(firstUnreadMessage) firstUnreadMessage.firstUnread = true;
        setConversation(conv);
      } else {
        alert('Unable to load that conversation');
      }
    };

    load();

  }, [conversationId]);

  const onSend = useCallback(async (text: string) => {
    const endpoint = sendMessageEndpoint.replace(':id', conversationId);
    try {
      const response = await axios.post(endpoint, { text });
      const message = response.data;
      setConversation((c) => {
        c.messages.push(message);
        return { ...c };
      });
    } catch (err) {
      alert('Unable to send message');
    }
  }, [conversationId]);

  if (!conversation) return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div>
      <div className="page-header">
        {onBack && (
          <button type="button" onClick={onBack} className="btn btn-secondary mb-3">
            <i className="fa fa-arrow-left" /> Back to messages
          </button>
        )}
        {conversation.archived && (
          <button type="button" onClick={onUnarchive} className="btn btn-dark mb-3 ms-2">
            <i className="fa fa-upload" /> Unarchive conversation
          </button>
        )}
        {!conversation.archived && (
          <button type="button" onClick={onArchive} className="btn btn-dark mb-3 ms-2">
            <i className="fa fa-download" /> Archive conversation
          </button>
        )}
        <h2>View conversation</h2>
      </div>
      <div className="row">
        <div className='col-sm d-flex flex-column'>
          {conversation.attachment_type === 'HousingApplication' && conversation.attachment && (
            <div className='mb-3'>
              <HousingApplicationCard housingApplication={conversation.attachment} currentUser={currentUser} />
            </div>
          )}
          {conversation.messages && conversation.messages.map((message) => (
            <Fragment key={message.id}>
              {message.firstUnread && (
                <div className="border-bottom border-primary text-primary mx-1 my-2">New messages</div>
              )}
              <MessageBalloon message={message} currentUser={currentUser} />
            </Fragment>
          ))}

          <div className="mt-3">
            <ConversationChatInput onSend={onSend} />
          </div>
        </div>
        <div className='col-sm col-md-4'>
          {conversation.subject.deleted_at && (
            <div className="alert alert-danger">
              <i className="fa fa-exclamation-triangle" /> This {conversation.subject_type.toLowerCase()} was deleted.
            </div>
          )}
          {conversation.subject_type === 'Listing' && conversation.subject && (
            <div>
              <p>Regarding:</p>
              <Listing listingDetails={conversation.subject} />
            </div>
          )}
          {conversation.attachment_type === 'Roommate' && conversation.attachment && (
            <div className='mb-3'>
              <RoommateCard roommate={conversation.attachment} />
            </div>
          )}
          {conversation.subject_type === 'Roommate' && conversation.subject && (
            <div>
              <p>Regarding:</p>
              <RoommateCard roommate={conversation.subject} />
            </div>
          )}
          {conversation.subject_type === 'Classified' && (
            <div>
              <p>Regarding:</p>
              <ClassifiedCard classified={conversation.subject} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
