import React, { useEffect } from 'react';
import { Purchase } from '@/types';
import axios from 'axios';

interface PurchaseShowPageProps {
  purchase: Purchase;
  refreshPath: string;
  successPath: string;
  errorPath: string;
}

const PULL_INTERVAL_MS = 1000;

function PurchasesShowPage({ purchase, refreshPath, successPath, errorPath }: PurchaseShowPageProps) {
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const response = await axios.get(refreshPath);
      const { status } = response.data as { status: Purchase['status'] };

      if (status === 'succeeded') window.location.href = successPath;
      else if (['canceled', 'payment_failed'].includes(status)) {
        alert('We were unable to process your transaction. Please try again.');
        window.location.href = errorPath;
      }
    }, PULL_INTERVAL_MS);

    return () => clearInterval(intervalId);
  }, [purchase, refreshPath]);

  return (
    <div className="row justify-content-md-center">
      <div className="col-md-8 col-lg-8">
        <header className="page-header">
          <h2>Please wait while we process your purchase...</h2>
        </header>
        <div>This usually only takes a few seconds. This page will redirect when it's done.</div>
      </div>
    </div>
  );
}

export default PurchasesShowPage;
