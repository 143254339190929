import React, { ChangeEventHandler, useState } from 'react';
import defaultImage from '@/assets/images/no_image_found.png';
import { School } from '@/types';

interface PortalSelectProps {
  schools: School[];
  userSchool: School;
  rootUrl: string;
}

export function PortalSelect({ schools, userSchool, rootUrl }: PortalSelectProps) {
  const [filteredSchools, setFilteredSchools] = useState([...schools]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
    const value = evt.target.value;
    setSearchTerm(value);
    setFilteredSchools(schools.filter((school) =>
      school.name.toLowerCase().includes(value.toLowerCase()) ||
      school.city.toLowerCase().includes(value.toLowerCase())
    ));
  };

  const searchBar = (
    <div className="col-md-6">
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Or search by name or city"
          aria-label="Or search by name or city"
          onChange={handleChange}
          value={searchTerm}
        />
        <div className="input-group-text">
          <i className="fa fa-search fa-sm m" />
        </div>
      </div>
    </div>
  );

  // removed images for now, because we don't have images for every school
  //<!--<img src={defaultImage} alt="school-logo" style={{ maxHeight: '80px' }} />-->
  return (
    <>
      {userSchool && (
        <div className="alert alert-info pb-3">
          <p>
            <a href={rootUrl.replace('REPLACE', userSchool.subdomain)} className='btn btn-primary'>
              Jump to the {userSchool.name} portal <i className='fa fa-arrow-right'></i>
            </a>
          </p>
          You can always
          use <a href={rootUrl.replace('REPLACE', userSchool.subdomain)} className='alert-link'>
            {rootUrl.replace('REPLACE', userSchool.subdomain).replace(/https?:\/\//, '').replace(/\/$/, '')}
          </a> directly to save a step.
          {/*
            You can also change your default school
            in <a href={`${rootUrl.replace('REPLACE', userSchool.subdomain)}account/settings`} className='alert-link'>
              account settings here.
            </a>
          */}
        </div>
      )}
      <div className='row pb-3 align-items-center'>
        <div className="col-md-6">
          <h4 id='select-a-school' className="mb-0">Select a school to begin</h4>
        </div>
        {searchBar}
      </div>
      {!userSchool && (
        <p>
          If you're not sure, just select the school closest to you
          (you can always change it later).
          If you're having any issues, please
          contact <a href="mailto:help@jumpoffcampus.com" className='alert-link'>help@jumpoffcampus.com</a>.
        </p>
      )}
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        {filteredSchools.map((school) => (
          <div className='col pb-2' key={school.id}>
            <a className="school" href={rootUrl.replace('REPLACE', school.subdomain)} key={school.id}>
              {school.colors.length > 0 && (
                <span className="school-colors">
                  {school.colors.map((color) => (
                    <span key={color} className="school-color" style={{ backgroundColor: color }} />
                  ))}
                </span>
              )}
              <span className="school-name">
                {school.name}
                <br />
                <small>{school.city}</small>
              </span>
            </a>
          </div>
        ))}
      </div>
    </>
  );
}
