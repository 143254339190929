import React from 'react';

interface ClassifiedCardProps {
  classified: any;
}

export function ClassifiedCard({ classified }: ClassifiedCardProps) {
  const carouselId = "carousel"+classified.id.toString();
  //const myCarousel = document.querySelector('#'+carouselId);
  //const carousel = new bootstrap.Carousel(myCarousel)

  return (
    <div className='card'>
      <div className='carousel slide' data-bs-ride='carousel' style={{ height: '200px', overflow: 'hidden' }} id={carouselId}>
        <div className='carousel-inner bg-dark card-img-top' style={{ height: '200px'}}>
          {classified.images.map((image, index) => (
            <div className={'carousel-item h-100 '+(index === 0 ? 'active' : '')} key={image}>
              <img src={image} className='d-block w-100 h-100' style={{objectFit: 'cover'}} />
            </div>
          ))}
          {false && classified.images.length > 1 && (
            <button className='carousel-control-prev' type='button' data-bs-slide='prev' data-bs-target={carouselId}>
              <span className='carousel-control-prev-icon' aria-hidden="true"></span>
              <span className='sr-only'>Previous</span>
            </button>
          )}
          {false && classified.images.length > 1 && (
            <button className='carousel-control-next' type='button' data-bs-slide='next' data-bs-target={carouselId}>
              <span className='carousel-control-next-icon' aria-hidden="true"></span>
              <span className='sr-only'>Next</span>
            </button>
          )}
        </div>
      </div>
      <div className='card-body'>
        <div className='card-title'>
          <b>{classified.title}</b>
        </div>
        <div className='mb-2'>
          <span>
            Price: {classified.price}<br />
          </span>
        </div>
        <a href={classified.route_path} className='btn btn-primary'>
          View classified ad
        </a>
      </div>
    </div>
  );
}

