import React from 'react';
import { xor } from 'lodash';
import { School, SchoolId } from '@/types';
import { Checkbox } from '@/components/Checkbox';
import { Money } from '@/components/Money';

interface CrosspostSelectorProps {
  schools: School[];
  selectedSchoolIds: SchoolId[];
  priceCentsPerCrosspost: number;
  onChange: (newSelectedSchoolIds: School['id'][]) => void;
}

export function CrosspostSelector({
  schools,
  selectedSchoolIds,
  priceCentsPerCrosspost,
  onChange,
}: CrosspostSelectorProps) {
  const toggle = (id: School['id']) => onChange(xor(selectedSchoolIds, [id]));

  return (
    <div>
      {schools.map((nearbySchool) => (
        <Checkbox
          key={nearbySchool.id}
          name="school_ids[]"
          value={nearbySchool.id}
          checked={selectedSchoolIds.includes(nearbySchool.id)}
          onChange={() => toggle(nearbySchool.id)}
        >
          {nearbySchool.name} (<Money cents={priceCentsPerCrosspost} />)
        </Checkbox>
      ))}
    </div>
  );
}
