import React, { useState, useEffect } from 'react';
import { Listing } from '@/components/Listing';
import { ListingId } from '@/types';

interface ListingCardsProps {
  signedIn: boolean;
  listingsArr: any[];
  skipSort?: boolean;
  skipCount?: boolean;
  maxCols?: number;
  favoritesArr?: any[];
  allBlocked?: boolean;
  emptyMessage: string;
}

export function ListingCards({ signedIn, listingsArr, skipSort, skipCount, maxCols, favoritesArr, allBlocked, emptyMessage }: ListingCardsProps) {
  // passed as argument on /housing - not passed in on student landing page
  maxCols = maxCols || 4;
  // passed as arguments on student landing page - not passed in on /housing
  skipSort = skipSort || false;
  skipCount = skipCount || false;
  favoritesArr = favoritesArr || [];

  const [listings, setListings] = useState<any[]>([]);
  const [sortBy, setSortBy] = useState('featured');

  useEffect(() => {
    let listingsWithFavorites = [...listingsArr];
    if (favoritesArr) {
      for (let listing of listingsWithFavorites) {
        listing.favorited = favoritesArr.includes(listing.id);
      }
    }

    if (allBlocked) {
      for (let listing of listingsWithFavorites) {
        listing.blocked = true;
      }
    }

    setListings(listingsWithFavorites);
  }, []);

  useEffect(() => {
    if (!skipSort) {
      handleSort(sortBy, listingsArr);
    }
  }, [sortBy, listingsArr]);

  const removeCard = (id: ListingId) => () => {
    setListings(listings.filter((listing) => listing.id !== id));
  };

  const handleSort = (value: any, unsortedListings: any[]) => {
    let orderedListings = [...unsortedListings];

    switch (value) {
      //sorts by featured, then by recency
      case 'featured':
        orderedListings.sort((a, b) => {
          if (!a.featured && b.featured) return 1;
          if (a.featured && !b.featured) return -1;
          return new Date(b.posted_at).getTime() - new Date(a.posted_at).getTime();
        });
        break;
      case 'recent':
        orderedListings.sort((a, b) => new Date(b.posted_at).getTime() - new Date(a.posted_at).getTime());
        break;
      case 'price':
        orderedListings.sort((a, b) => a.price - b.price);
        break;
      case 'pricePerBed':
        orderedListings.sort((a, b) => a.price / a.beds - b.price / b.beds);
        break;
      case 'beds':
        orderedListings.sort((a, b) => a.beds - b.beds);
        break;
    }
    setListings(orderedListings);
  };

  let rowClass = 'row row-cols-1 ';
  if (maxCols === 4) rowClass += 'row-cols-sm-2 row-cols-md-3 row-cols-lg-4';
  else rowClass += 'row-cols-md-2 row-cols-lg-3';

  return (
    <div>
      <div className='row mb-3'>
      {!skipCount && (
        <div className='col-12 col-sm-4'>
          <p className='lead m-0 p-1'>{listings.length} {listings.length === 1 ? 'listing' : 'listings'} found</p>
        </div>
      )}
      {!skipSort && (
        <div className='col-12 col-sm-4'>
          <form>
            <select id="sort-listings" className="form-select" onChange={(evt) => setSortBy(evt.target.value)}>
              <option value="featured">Sort by featured</option>
              <option value="recent">Sort by most recent</option>
              <option value="pricePerBed">Sort by price per bed</option>
              <option value="price">Sort by price</option>
              <option value="beds">Sort by number of beds</option>
            </select>
          </form>
        </div>
      )}
      </div>
      {listings.length ? (
        <div className={rowClass}>
          {listings.map((listing) => (
            <Listing
              key={listing.id}
              signedIn={signedIn}
              listingDetails={listing}
              blockCallback={removeCard(listing.id)}
            />
          ))}
        </div>
      ) : (
        <p className="lead text-center">{emptyMessage || 'Sorry, no listings match those criteria.'}</p>
      )}
    </div>
  );
}
