import React from 'react';

interface PageErrorsProps {
  errors: string[];
}

export function PageErrors({ errors }: PageErrorsProps) {
  if (!errors.length) return null;

  return (
    <div className="alert alert-danger" role="alert">
      <p>{errors.length === 1 ? 'An error' : 'Some errors'} prevent this transaction:</p>
      <ul className="text-danger text-left">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );
}
