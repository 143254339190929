export function getQueryParam(name: string): string | undefined {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(name) || undefined;
}

export function getIdQueryParam<T extends number>(name: string): T | undefined {
  const param = getQueryParam(name);
  if (!param) return undefined;

  return parseInt(param, 10) as T;
}
