import React from 'react';

interface ListingContainerTabsProps {
  listView: boolean;
  setListView: (listView: boolean) => void;
}

export function ListingContainerTabs({ listView, setListView }: ListingContainerTabsProps) {
  return (
    <ul className="nav nav-tabs my-3">
      <li className="nav-item">
        <a
          className={`nav-link ${listView ? 'active' : ''}`}
          onClick={() => {
            setListView(true);
          }}
          style={{ cursor: 'pointer' }}
        >
          List View
        </a>
      </li>

      <li className="nav-item">
        <a
          className={`nav-link ${listView ? '' : 'active'}`}
          onClick={() => {
            setListView(false);
          }}
          style={{ cursor: 'pointer' }}
        >
          Map View
        </a>
      </li>
    </ul>
  );
}
