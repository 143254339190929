import React, { FormEvent, FormEventHandler, useState } from 'react';
import { StripeElementChangeEvent } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { User, PaymentMethod } from '@/types';
import { Stripe } from '@/components/Stripe';
import { StripeCard } from '@/components/Stripe/Card';
import { StripeCardSelector } from '@/components/Stripe/Card/Selector';
import { StripeFormSubmit } from './Submit/StripeFormSubmit';
import axios from 'axios';

interface StripeFormProps {
  user: User;
  payment_methods: PaymentMethod[];
  purchaseData: Record<string, any>;
  amountCents: number;
  endpoint: string;
}

function _StripeForm({ user, payment_methods, purchaseData, amountCents, endpoint }: StripeFormProps) {
  const stripe = useStripe()!;
  const elements = useElements()!;
  const [error, setError] = useState<{ message?: string }>();
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [selectedCard, setSelectedCard] = useState<string>(payment_methods.length ? '' : 'new');
  const billingDetails = { name: `${user.first_name} ${user.last_name}`, email: user.email, phone: user.phone };

  const submitToEndpoint = async (paymentMethodId: string | null) => {
    const { data } = await axios.post(endpoint, {
      payment_method_id: paymentMethodId,
      amount_cents: amountCents,
      ...purchaseData,
    });

    return data;
  };

  const submitToEndpointWithSCAConfirmation = async (paymentMethodId: string) => {
    const data = await submitToEndpoint(paymentMethodId);

    if (data.error) {
      setError(data.error);
      return;
    }

    // 3D Secure
    if (data.status === 'payment_requires_action') {
      const { error: confirmationError } = await stripe.confirmCardPayment(data.client_secret, {
        setup_future_usage: 'off_session',
        save_payment_method: true,
      });

      if (confirmationError) {
        setError(confirmationError);
        return;
      }
    }

    window.location.href = data.success_path;
  };

  const createPaymentMethodId = async () => {
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)!,
      billing_details: billingDetails as any,
    });

    if (payload.error) {
      setError(payload.error);
    } else if (payload.paymentMethod) {
      return payload.paymentMethod.id;
    }
  };

  const radioChange = (card: string) => {
    setError();
    setSelectedCard(card);
  };

  const handleChange = (event: StripeElementChangeEvent) => {
    setError(event.error);
    setCardComplete(event.complete);
  };

  const handleFreeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setProcessing(true);
    const data = await submitToEndpoint(null);
    window.location.href = data.success_path;
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (selectedCard === 'new' && !cardComplete) return;

    setProcessing(true);

    try {
      if (selectedCard === 'new') {
        const paymentMethodId = await createPaymentMethodId();
        if (paymentMethodId) await submitToEndpointWithSCAConfirmation(paymentMethodId);
      } else {
        const processorId = payment_methods.find(({ id }) => id === selectedCard).processor_id;
        await submitToEndpointWithSCAConfirmation(processorId);
      }
    } catch (err) {
      if (err.response?.data?.error) {
        // The client was given an error response (5xx, 4xx)
        setError({message: err.response.data.error});
      } else {
        setError({ error: "Sorry, there was an error communicating with our server. Please try again."});
      }
    } finally {
      setProcessing(false);
    }
  };

  const submit: FormEventHandler<HTMLFormElement> = (event) => {
    if (amountCents === 0) handleFreeSubmit(event);
    else handleSubmit(event);
  };

  return (
    <form onSubmit={submit}>
      {amountCents > 0 ? (
        <>
          <StripeCardSelector value={selectedCard} payment_methods={payment_methods} onChange={radioChange} />
          {selectedCard === 'new' && <StripeCard onChange={handleChange} />}

          {error && <div className="text-danger mt-2">{error.message}</div>}
        </>
      ) : null}

      <footer className="mt-4">
        <StripeFormSubmit
          processing={processing}
          disabled={!stripe || !elements}
          amountCents={amountCents}
        />
        {amountCents !== 0 &&
          <div className="font-italic mt-1 mb-4">
            When you click "Pay", we will charge your card for the total above, and store it securely for future use.
          </div>
        }
      </footer>
    </form>
  );
}

export const StripeForm = (props: StripeFormProps) => (
  <Stripe>
    <_StripeForm {...props} />
  </Stripe>
);
