import React, { Fragment } from 'react';
import { Poi } from '@/types';

interface ListingInfoWindowPoiProps {
  poi: Poi;
}

export function ListingInfoWindowPoi({ poi }: ListingInfoWindowPoiProps) {
  return (
    <div>
      {poi.read_more_url ? (
        <a href={poi.read_more_url} target="_blank">
          {poi.title}
        </a>
      ) : (
        <p>{poi.title}</p>
      )}
      <p>
        {poi.description.split("\n").map((value, index) => {
          return (
            <Fragment key={index}>
              {value}
              <br />
            </Fragment>
          )
        })}
      </p>
    </div>
  );
}
