import React from 'react';
import { Message, User } from '@/types';
import classNames from 'classnames';

interface MessageBaloonProps {
  message: Message;
  currentUser: User;
}

export function MessageBalloon({ message, currentUser }: MessageBaloonProps) {
  const fromCurrentUser = currentUser.id === message.sender_user_id;
  const timestamp = new Date(message.created_at*1000).toLocaleString();

  return (
    <div
      className={classNames(
        'd-flex flex-column p-3 mb-2 rounded',
        fromCurrentUser ? 'border border-dark align-self-end' : 'bg-dark text-white',
      )}
      style={{ width: 640, maxWidth: '80%' }}
    >
      <div style={{whiteSpace: 'pre-line'}}>{message.text}</div>
      <small className={fromCurrentUser ? 'text-muted' : ''}>
        Sent by {fromCurrentUser ? 'you' : 'them'} on {timestamp}
      </small>
    </div>
  );
}
