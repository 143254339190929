import React, { useEffect } from 'react';
import { Roommate } from '@/types';
import { DateDisplay } from '@/components/Date/Display';

interface RoommateCardProps {
  roommate: Roommate;
}

export function RoommateCard({ roommate }: RoommateCardProps) {
  const carouselId = "carousel"+roommate.id.toString();
  //const myCarousel = document.querySelector('#'+carouselId);
  //const carousel = new bootstrap.Carousel(myCarousel)

  return (
    <div className='card'>
      <div className='carousel slide' data-bs-ride='carousel' style={{ height: '200px', overflow: 'hidden' }} id={carouselId}>
        <div className='carousel-inner bg-dark card-img-top' style={{ height: '200px'}}>
          {roommate.images.map((image, index) => (
            <div className={'carousel-item h-100 '+(index === 0 ? 'active' : '')} key={image}>
              <img src={image} className='d-block w-100 h-100' style={{objectFit: 'cover'}} />
            </div>
          ))}
          {false && roommate.images.length > 1 && (
            <button className='carousel-control-prev' type='button' data-bs-slide='prev' data-bs-target={carouselId}>
              <span className='carousel-control-prev-icon' aria-hidden="true"></span>
              <span className='sr-only'>Previous</span>
            </button>
          )}
          {false && roommate.images.length > 1 && (
            <button className='carousel-control-next' type='button' data-bs-slide='next' data-bs-target={carouselId}>
              <span className='carousel-control-next-icon' aria-hidden="true"></span>
              <span className='sr-only'>Next</span>
            </button>
          )}
        </div>
      </div>
      <div className='card-body'>
        <div className='card-title'>
          <b>{roommate.display_name}'s roommate profile</b>
        </div>
        <div className='mb-2'>
          {roommate.pronouns && (
            <span>
              Pronouns: {roommate.pronouns}<br />
            </span>
          )}
          {roommate.move_in && (
            <span>
              Move-in date: {roommate.move_in}<br />
            </span>
          )}
          {roommate.group_size && (
            <span>
              Group size: {roommate.group_size}<br />
            </span>
          )}
        </div>
        <a href={roommate.route_path} className='btn btn-primary'>
          View full roommate profile
        </a>
      </div>
    </div>
  );
}
