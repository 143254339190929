import React from 'react';
import { Favorite } from '@/components/Favorite';
import { BlockButton } from '@/components/BlockButton';
import defaultImage from '@/assets/images/no_image_found.png';

interface ListingProps {
  signedIn: boolean;
  listingDetails: any;
  blockCallback: () => void;
}

export function Listing({ signedIn, listingDetails, blockCallback }: ListingProps) {
  return (
    <div className="col mb-4">
      <div className="card listing-card">
        { listingDetails.featured &&
          <a href={listingDetails.listing_path || listingDetails.route_path} target="_blank" className="featured badge rounded-pill bg-danger">
            Featured
          </a>
        }

        <Favorite signedIn={signedIn} listingId={listingDetails.id} favorited={listingDetails.favorited} />

        <BlockButton
          signedIn={signedIn}
          listingId={listingDetails.id}
          blocked={listingDetails.blocked}
          blockCallback={blockCallback}
        />

        <a href={listingDetails.listing_path || listingDetails.route_path} target="_blank">
          <img
            src={listingDetails.images.length ? listingDetails.images[0] : defaultImage}
            className="card-img-top"
            alt={listingDetails.title}
          />
        </a>
        <div className="card-body">
          <div className="card-title">
            <a href={listingDetails.listing_path || listingDetails.route_path} target="_blank">{listingDetails.title}</a>
          </div>
          <div className="card-text">
            ${listingDetails.price} / {listingDetails.beds} beds
          </div>
          <div className="card-text">{listingDetails.layout}</div>
          <div className="card-text pb-2">Move In: {listingDetails.start_date}</div>
          {listingDetails.posted_at && (
            <div className="card-text text-muted">
              Posted: {new Date(listingDetails.posted_at*1000).toLocaleDateString()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
