import { Money } from '@/components/Money';
import React from 'react';

export interface BillingReceiptItem {
  name: string;
  amountCents: number;
}

interface BillingReceiptProps {
  items: BillingReceiptItem[];
  totalAmountCents: number;
}

export function BillingReceipt({ items, totalAmountCents }: BillingReceiptProps) {
  return (
    <div>
      <div>
        {items.map((item, index) => (
          <BillingReceiptRow key={index} item={item} />
        ))}
      </div>

      <div className="row bg-light py-2" style={{ fontSize: '1.25rem' }}>
        <div className="col-6">Total</div>
        <div className="col-6 text-right">
          <Money cents={totalAmountCents} />
        </div>
      </div>
    </div>
  );
}

interface BillingReceiptItemProps {
  item: BillingReceiptItem;
}

function BillingReceiptRow({ item }: BillingReceiptItemProps) {
  return (
    <div className="row mb-2">
      <div className="col-6">{item.name}</div>

      <div className="col-6 text-right">
        <Money cents={item.amountCents} />
      </div>
    </div>
  );
}
