import React, { useState } from 'react';
import { GoogleMap, useLoadScript, MarkerClusterer, Marker, InfoWindow, TransitLayer } from '@react-google-maps/api';
import { getPOIMarkerArray, PoiMarker } from '@/components/helpers/poiMarkers';
import { ListingWithPathImages, Poi } from '@/types';
import { ListingInfoWindow } from '@/components/Listing/InfoWindow';

interface ListingMapProps {
  listings: Record<string, ListingWithPathImages[]>;
  mapOverlaysShown: any;
  schoolLatLong: google.maps.LatLngLiteral;
  pois: Poi[];
}

export function ListingMap({ listings, mapOverlaysShown, schoolLatLong, pois }: ListingMapProps) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: window.ENV.GOOGLE_MAPS_KEY,
  });
  const [openInfoWindows, setOpenInfoWindows] = useState<number[]>([]);

  const initialCenter = schoolLatLong;
  const initialZoom = 11;
  const options = {
    mapTypeControl: false,
    minZoom: 1, //TODO this used to be 6 - should it still?
    maxZoom: 18,
  };
  const maxClusterZoom = 14;

  const getMarkersAndBounds = () => {
    let markerArray: PoiMarker[] = [];
    let bounds = new google.maps.LatLngBounds();

    for (let key in listings) {
      let listingsByLatLong = listings[key];
      let position = new google.maps.LatLng(
        listingsByLatLong[0].latitude as number,
        listingsByLatLong[0].longitude as number
      );

      bounds.extend(position);
      markerArray.push({
        listings: listingsByLatLong,
        position: position,
        address: key,
        label: listingsByLatLong.length < 10 ? listingsByLatLong.length.toString() : '+',
      });
    }

    if (mapOverlaysShown.points_of_interest) {
      const poisArr = getPOIMarkerArray(pois);
      markerArray = markerArray.concat(poisArr);
    }

    return { markers: markerArray, bounds: bounds };
  };

  const openInfoWindow = (index: number) => {
    setOpenInfoWindows([...openInfoWindows, index]);
  };

  const closeInfoWindow = (index: number) => {
    setOpenInfoWindows(openInfoWindows.filter((val) => val !== index));
  };

  const renderMap = () => {
    const { markers, bounds } = getMarkersAndBounds();

    return (
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '600px' }}
        center={initialCenter}
        zoom={initialZoom}
        options={options}
        onLoad={(map) => {
          map.fitBounds(bounds);
          map.panToBounds(bounds);
        }}
      >
        {mapOverlaysShown.transit && <TransitLayer />}
        <MarkerClusterer
          options={{
            imagePath: "/markerClusters/m",
            maxZoom: maxClusterZoom
          }}
        >
          {(clusterer) =>
            markers.map((marker, index) => (
              <Marker
                key={index}
                position={marker.position}
                label={marker.label}
                onClick={() => openInfoWindow(index)}
                clusterer={clusterer}
              >
                {openInfoWindows.includes(index) && (
                  <InfoWindow onCloseClick={() => closeInfoWindow(index)}>
                    <ListingInfoWindow poiMarker={marker} />
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : null;
}
