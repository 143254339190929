import React, { ReactNode } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

let stripePromise: any;

// Only load stripe after it's called
const getStripePromise = () => {
  // @ts-ignore
  if (!stripePromise) stripePromise = loadStripe(window.ENV['STRIPE_PUBLIC_KEY']);
  return stripePromise;
};

interface StripeProps {
  children: ReactNode;
}

export function Stripe({ children }: StripeProps) {
  return <Elements stripe={getStripePromise()}>{children}</Elements>;
}
