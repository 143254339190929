import React, { ReactNode } from 'react';

interface BillingSectionProps {
  title: ReactNode;
  children: ReactNode;
}

export function BillingSection({ title, children }: BillingSectionProps) {
  return (
    <section className="mb-4">
      {title && (
        <header className="mb-2">
          <h4>{title}</h4>
        </header>
      )}

      {children}
    </section>
  );
}
