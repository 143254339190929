import React, { ChangeEventHandler, FormEventHandler, useState } from 'react';
import { Coupon } from '@/types';
import axios from 'axios';

interface CouponInputProps {
  endpoint: string;
  onChange: (coupon: Coupon | undefined) => void;
}

export function CouponInput({ endpoint, onChange }: CouponInputProps) {
  const [couponCode, setCouponCode] = useState('');
  const [couponMessage, setCouponMessage] = useState<{ type: string; message: string }>();

  const change: ChangeEventHandler<HTMLInputElement> = (event) => {
    setCouponCode(event.target.value);
  };

  const submit: FormEventHandler = async (event) => {
    event.preventDefault();

    try {
      const {
        data: { coupon },
      } = await axios.post(endpoint, { code: couponCode });

      setCouponMessage({ type: 'success', message: 'Coupon applied' });
      onChange(coupon);
    } catch (error) {
      if (error.response?.data?.message) {
        setCouponMessage({ type: 'danger', message: error.response?.data?.message });
      } else {
        setCouponMessage({ type: 'danger', message: 'Invalid coupon' });
      }
      onChange(undefined);
    }
  };

  return (
    <form className="row row-cols-sm-auto g-3 align-items-center" onSubmit={submit}>
      <div className="col-12">
        <input
          type="text"
          className="form-control"
          value={couponCode}
          onChange={change}
          placeholder="CODE"
          required
        />
      </div>

      <div className="col-12">
        <button type="submit" className="btn btn-primary">
          Apply
        </button>
      </div>

      <div className="col-12">
        {couponMessage && <div className={`text-${couponMessage.type}`}>{couponMessage.message}</div>}
      </div>
    </form>
  );
}
