import React, { useMemo } from 'react';
import { uniqueId } from 'lodash';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

interface StripeCardProps {
  onChange: (e: StripeElementChangeEvent) => void;
}

export function StripeCard({ onChange }: StripeCardProps) {
  const cardElementId = useMemo(() => uniqueId('card-element'), []);

  return (
    <div className="ps-2">
      <label htmlFor={cardElementId}>Enter your credit or debit card:</label>

      <CardElement
        id={cardElementId}
        options={{
          style: {
            base: {
              color: '#32325d',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          },
        }}
        onChange={onChange}
      />
    </div>
  );
}
