import React, { useState } from 'react';
import axios from 'axios';
import { ListingId } from '@/types';
import { requireLogin } from '@/components/helpers/authHelpers';

interface FavoriteProps {
  signedIn: boolean;
  listingId: ListingId;
  favorited: boolean;
}

export function Favorite({ signedIn, listingId, favorited }: FavoriteProps) {
  const [isFavorited, setIsFavorited] = useState(favorited);

  const toggleFavorite = async () => {
    const endpoint = `/api/listings/${listingId}/favorites`;
    isFavorited ? await axios.delete(endpoint) : await axios.post(endpoint);
    setIsFavorited((oldState) => !oldState);
  };

  const click = () => {
    if (!signedIn) return requireLogin();
    toggleFavorite();
  };

  return <i onClick={click} className={isFavorited ? 'favorite fa fa-heart' : 'favorite fa fa-heart-o'} />;
}
