import React, { ReactNode } from 'react';
import { useRadioContext } from '../RadioContext';

interface RadioItemProps {
  value: any;
  children: ReactNode;
}

export function RadioItem({ value, children }: RadioItemProps) {
  const { value: currentValue, select } = useRadioContext();

  return (
    <div className="radio">
      <label>
        <input type="radio" onChange={() => select(value)} checked={currentValue === value} /> {children}
      </label>
    </div>
  );
}
