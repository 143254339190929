import React, { useState } from 'react';
import axios from 'axios';
import { ListingId } from '@/types';
import { requireLogin } from '@/components/helpers/authHelpers';

interface BlockButtonProps {
  signedIn: boolean;
  listingId: ListingId;
  blocked: boolean;
  blockCallback?: () => void;
}

export function BlockButton({ signedIn, listingId, blocked, blockCallback }: BlockButtonProps) {
  const [isBlocked, setIsBlocked] = useState(blocked);

  const toggleBlockState = async () => {
    const msg = isBlocked ?
      'This listing will now visible in search results.' :
      'Hide this listing from search results?'

    if (window.confirm(msg)) {
      const endpoint = `/api/listings/${listingId}/blocks`;
      isBlocked ? await axios.delete(endpoint) : await axios.post(endpoint);

      setIsBlocked((oldState) => !oldState);
      if (blockCallback) blockCallback();
    }
  };

  const click = () => {
    if (!signedIn) return requireLogin();
    toggleBlockState();
  };

  return (
    <i onClick={click} className={isBlocked ? 'listing-block fa fa-trash' : 'listing-block fa fa-trash inactive'} />
  );
}
