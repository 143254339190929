import React, { Fragment } from 'react';
import defaultImage from '@/assets/images/no_image_found.png';
import { ListingWithPathImages } from '@/types';

interface ListingInfoWindowProps {
  listings: ListingWithPathImages[];
}

export function ListingInfoWindowListings({ listings }: ListingInfoWindowProps) {
  return (
    <Fragment>
      {listings.map((listingDetails) => {
        return (
          <div key={listingDetails.id} className="card m-2" style={{ maxWidth: '200px' }}>
            <a href={listingDetails.listing_path} target="_blank">
              <img
                src={listingDetails.images.length ? listingDetails.images[0] : defaultImage}
                className="card-img-top"
                alt="listing-img"
                style={{ width: '100%' }}
              />
            </a>
            <div className="card-body">
              <div className="card-title fw-bold">{listingDetails.title}</div>
              <div className="card-text">
                ${listingDetails.price} / {listingDetails.beds} beds
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
}
