import { ConversationId, User } from '@/types';
import { useState, useEffect } from 'react';
import { sortBy } from 'lodash';
import { getQueryParam, getIdQueryParam } from '@/components/helpers/queryParam';
import axios from 'axios';

interface UseConversationsProps {
  currentUser: User;
  getConversationsEndpoint: string;
  getConversationEndpoint: string;
  archiveConversationEndpoint: string;
  unarchiveConversationEndpoint: string;
  sendMessageEndpoint: string;
}

export function useConversations({
  getConversationsEndpoint,
  archiveConversationEndpoint,
  unarchiveConversationEndpoint,
  sendMessageEndpoint,
  currentUser,
}: UseConversationsProps) {
  const [loading, setLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [metadata, setMetadata] = useState({});

  const [params, setParams] = useState({
    page: 1,
    subject_type: getQueryParam('subject_type'),
    subject_id: getIdQueryParam('subject_id'),
    archived: false,
    search: '',
  });

  const wrappedSetParams = (p) => {
    const url = new URL(window.location);
    url.searchParams.delete('conversation_id');
    if (p.subject_id) url.searchParams.set('subject_id', p.subject_id);
    else url.searchParams.delete('subject_id');
    if (p.subject_id) url.searchParams.set('subject_type', p.subject_id);
    else url.searchParams.delete('subject_type');
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(url, Turbolinks.uuid())

    setParams(p);
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);

      const response = await fetch(`${getConversationsEndpoint}?page=${params.page}&subject_type=${params.subject_type}&subject_id=${params.subject_id}&archived=${params.archived}&search=${params.search}`);
      const data = await response.json();

      setSubjectList(data.subjectList);
      setConversations(
        sortBy(data.conversations, (c) => c.last_message_at)
        .reverse()
      );
      setMetadata(data.metadata);
      setLoading(false);
    };

    load();

  }, [params]);

  const archive = async (conversationId: ConversationId) => {
    try {
      await axios.post(archiveConversationEndpoint.replace(':id', conversationId));
    } catch {
      alert('Unable to archive conversation');
    }
  };

  const unarchive = async (conversationId: ConversationId) => {
    try {
      await axios.post(unarchiveConversationEndpoint.replace(':id', conversationId));
    } catch {
      alert('Unable to unarchive conversation');
    }
  };

  return {
    archive,
    unarchive,
    conversations,
    setParams: wrappedSetParams,
    params,
    subjectList,
    setConversations,
    metadata,
    loading,
  };
}
