import React from 'react';
import { GoogleMap, useLoadScript, Marker, Circle } from '@react-google-maps/api';

interface SinglePinMapProps {
  latitude: number;
  longitude: number;
  pinType: string | null;
}

export function SinglePinMap({ latitude, longitude, pinType }: SinglePinMapProps) {
  const initialCenter = { lat: latitude, lng: longitude };
  const initialZoom = pinType === 'circle' ? 12 : 14;
  const options = {
    minZoom: 0,
    maxZoom: 18,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: window.ENV.GOOGLE_MAPS_KEY });

  const renderMap = () => {
    return (
      <GoogleMap mapContainerStyle={{ height: '300px' }} center={initialCenter} zoom={initialZoom} options={options}>
        {pinType === 'circle' ? (
          <Circle center={initialCenter} radius={1000} />
        ) : (
          <Marker position={initialCenter} />
        )}
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : null;
}
