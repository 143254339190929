import React, { useState } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { getPOIMarkerArray } from '@/components/helpers/poiMarkers';
import { ListingInfoWindow } from '@/components/Listing/InfoWindow';
import { Poi } from '@/types';

interface PoiMapProps {
  pois: Poi[];
}

export function PoiMap({ pois }: PoiMapProps) {
  const initialCenter = { lat: pois[0].latitude, lng: pois[0].longitude };
  const initialZoom = 14;
  const options = {
    mapTypeControl: false,
    mapLoaded: false,
    minZoom: 1, //TODO this used to be 6 - should it still?
    maxZoom: 18,
  };

  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: window.ENV.GOOGLE_MAPS_KEY });

  const [openInfoWindows, setOpenInfoWindows] = useState<number[]>([]);

  const openInfoWindow = (index: number) => {
    setOpenInfoWindows([...openInfoWindows, index]);
  };

  const closeInfoWindow = (index: number) => {
    setOpenInfoWindows(openInfoWindows.filter((val) => val !== index));
  };

  const renderMap = () => {
    const markers = getPOIMarkerArray(pois);

    return (
      <GoogleMap
        mapContainerStyle={{ width: '300px', height: '300px' }}
        center={initialCenter}
        zoom={initialZoom}
        options={options}
      >
        {markers.map((marker, index) => (
          <Marker key={index} position={marker.position} label={marker.label} onClick={() => openInfoWindow(index)}>
            {openInfoWindows.includes(index) && (
              <InfoWindow onCloseClick={() => closeInfoWindow(index)}>
                <ListingInfoWindow poiMarker={marker} />
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    );
  };

  return isLoaded ? renderMap() : null;
}
