import React from 'react';
import { PoiMarker } from '@/components/helpers/poiMarkers';
import { ListingInfoWindowListings } from './Listings';
import { ListingInfoWindowPoi } from './Poi';

interface ListingInfoWindowProps {
  poiMarker: PoiMarker;
}

export function ListingInfoWindow({ poiMarker }: ListingInfoWindowProps) {
  if (poiMarker.listings) return <ListingInfoWindowListings listings={poiMarker.listings} />;
  if (poiMarker.poi) return <ListingInfoWindowPoi poi={poiMarker.poi} />;

  throw new Error('Unable to show listing info window for poiMarker.');
}
