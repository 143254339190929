import React, { useMemo } from 'react';

interface DateDisplayProps {
  dateString: string;
  showTime?: boolean;
}

export function DateDisplay({ dateString }: DateDisplayProps) {
  const formattedDate = useMemo(() => {
    // TODO: time style
    return new Date(dateString).toLocaleString([]);
  }, [dateString]);

  return <span>{formattedDate}</span>;
}
