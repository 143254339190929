import React, { ChangeEventHandler, FormEventHandler, useState } from 'react';

interface ConversationChatProps {
  onSend: (text: string) => void;
}

export function ConversationChatInput({ onSend }: ConversationChatProps) {
  const [text, setText] = useState('');

  const change: ChangeEventHandler<HTMLInputElement> = (event) => {
    setText(event.target.value);
  };

  const submit: FormEventHandler = async (event) => {
    event.preventDefault();
    await onSend(text);
    setText('');
  };

  return (
    <form onSubmit={submit} className="row g-3 align-items-center">
      <div className="col">
        <textarea
          className="form-control"
          value={text}
          onChange={change}
          placeholder="Message..."
          rows={2}
          required
        />
      </div>

      <div className="col-sm-auto align-self-stretch">
        <button className="btn btn-primary w-100 h-100" type="submit">
          <span className="fa fa-send me-1" /> Send
        </button>
      </div>
    </form>
  );
}
