import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ListingMap } from '@/components/Listing/Map';
import { ListingCards } from '@/components/Listing/Cards';
import { SearchBarForm } from '@/components/SearchBarForm';
import { ListingContainerTabs } from '@/components/Listing/Container/Tabs';
import { ListingId, ListingWithFavorited } from '@/types';

interface ListingContainerProps {
  signedIn: boolean;
  school: any;
  pois: any[];
  searchParams: any;
}

export function ListingContainer({ signedIn, school, pois, searchParams }: ListingContainerProps) {
  const [loading, setLoading] = useState(true);
  const [listingsArr, setListingsArr] = useState<ListingWithFavorited[]>([]);
  const [listingsObjByLongLat, setListingsObjByLongLat] = useState({});
  const [listView, setListView] = useState(true);
  const [mapOverlays, setMapOverlays] = useState({
    points_of_interest: true,
    transit: false,
    heatmap: false,
  });

  const parseListings = (listings: ListingWithFavorited[], favorites: ListingId[], blocks: ListingId[]) => {
    // Object for Map View = {
    //  "longitude, latitude string 1": [{listing1}, {listing2}],
    //  "longitude, latitude string 2": [{listing3}]
    // }
    let newListingsObjByLngLat: Record<string, ListingWithFavorited[]> = {};

    // Array for List View = [{listing1}, {listing2}, {listing3}]
    let newListingsArr = [];

    for (let listing of listings) {
      if (blocks.includes(listing.id)) {
        continue;
      }

      const longLatStr = `${listing.longitude}, ${listing.latitude}`;

      listing.favorited = favorites.includes(listing.id);

      newListingsArr.push(listing);

      if (longLatStr in newListingsObjByLngLat) {
        newListingsObjByLngLat[longLatStr].push(listing);
      } else {
        newListingsObjByLngLat[longLatStr] = [listing];
      }
    }

    setLoading(false);
    setListingsArr(newListingsArr);
    setListingsObjByLongLat(newListingsObjByLngLat);
  };

  const getListings = async (formState: any) => {
    setLoading(true);
    let [listingsResp, favoritesResp, blocksResp] = await Promise.all([
      axios.get(`/api/listings`, { headers: { 'Content-Type': 'application/json' }, params: formState }),
      axios.get(`/api/favorites`, { headers: { 'Content-Type': 'application/json' } }),
      axios.get(`/api/listing_blocks`, { headers: { 'Content-Type': 'application/json' } }),
    ]);
    parseListings(listingsResp.data, favoritesResp.data, blocksResp.data);
  };

  useEffect(() => {
    getListings(searchParams);
  }, []);

  return (
    <div className="map-listings">
      <SearchBarForm
        mapOverlaysShown={mapOverlays}
        setMapOverlays={setMapOverlays}
        listView={listView}
        poisExist={!!pois.length}
        searchParams={searchParams}
        submitHandler={getListings}
      />

      { loading ? (
        <p className='lead text-center pt-5'>
          <i className="fa fa-circle-o-notch fa-spin fs-2 mb-2" />
          <br />
          Loading...
        </p>
      ) : (
        <>

          <ListingContainerTabs listView={listView} setListView={setListView} />

          <div style={{ display: listView ? 'inline' : 'none' }}>
            <ListingCards signedIn={signedIn} listingsArr={listingsArr} />
          </div>

          {/* requires visibility instead of display to render map properly
          https://stackoverflow.com/questions/11098700/fitbounds-shows-whole-earth-if-map-is-first-hidden-and-then-shown */}
          <div style={{ visibility: listView ? 'hidden' : 'visible' }}>
            <ListingMap
              listings={listingsObjByLongLat}
              mapOverlaysShown={mapOverlays}
              schoolLatLong={{ lat: school.latitude, lng: school.longitude }}
              pois={pois}
            />
          </div>
        </>
      )}
    </div>
  );
}
