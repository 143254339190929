import React from 'react';
import { ListingWithPathImages, Poi } from '@/types';

const iconObj = {
  ambulance: '\uf0f9',
  info: '\uf05a',
  graduation: '\uf19d',
  home: '\uf015',
  coffee: '\uf0f4',
  bed: '\uf236',
  landmark: '\uf19c',
  paw: '\uf1b0',
  book: '\uf02d',
  camera: '\uf030',
  cutlery: '\uf0f5',
  bus: '\uf207',
  'h-square': '\uf0fd',
  'shopping-basket': '\uf291',
  'futbol-o': '\uf1e3',
  'circle-o-notch': '\uf1ce',
};

export interface PoiMarker {
  poi?: Poi;
  listings?: ListingWithPathImages[];
  position: google.maps.LatLng;
  address: string;
  label: google.maps.MarkerLabel | string;
  icon?: {
    url: string;
    labelOrigin: google.maps.Point;
  };
}

export function getPOIMarkerArray(pois: Poi[]): PoiMarker[] {
  return pois.map(
    (poi): PoiMarker => ({
      poi,
      position: new google.maps.LatLng(poi.latitude, poi.longitude),
      address: poi.address,
      label: {
        fontFamily: 'fontAwesome',
        text: iconObj[poi.icon as keyof typeof iconObj], //code for font-awesome icon
        fontSize: '15px',
        color: 'white',
      },
      icon: {
        url: 'https://ucarecdn.com/30597717-a107-46d3-9ae9-570b8a3dfaf4/-/resize/25x/',
        labelOrigin: new google.maps.Point(12.5, 12),
      },
    })
  );
}
