// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import '@rails/actiontext';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import axios from 'axios';
import 'channels';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'fontawesome-4.7/css/font-awesome.min.css';
import './global/scss/index.scss';
import ahoy from "ahoy.js";
import 'trix';

global.jQuery = require('jquery');
global.$ = global.jQuery;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Configure axios
const csrfToken = document.querySelector('meta[name=csrf-token]').content;
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;

document.addEventListener('turbolinks:load', function () {
  // Need to explicitly initialize all bootstrap js,
  // cause the page only technically loads once!
  $('[data-bs-toggle="dropdown"]').dropdown();
  $('[data-bs-toggle="popover"]').popover();
  $('.carousel').carousel();
  var parts = window.location.host.split('.')
  var subdomain = parts.splice(0, parts.length - 2).join('.')
  ahoy.trackView({subdomain});
});

var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);


/*
 * UJS to show/hide a element based on a checkbox or radio selection
 * Usage:
 *    <input type="checkbox" data-checkbox-show="#some-query-selector" />
 *    <div id="some-query-selector">...</div>
 * Now when the checkbox is checked, the element will be shown, otherwise hidden.
 * For the inverse behavior, use data-checkbox-hide instead of data-checkbox-show.
 * Also works on radio buttons.
 */
document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('[data-checkbox-show]').forEach((checkbox) => {
    const selector = checkbox.dataset.checkboxShow;
    const targets = document.querySelectorAll(selector);
    if(!targets.length) return;
    const update = () => {
      if (checkbox.checked) {
        targets.forEach((target) => {
          target.querySelectorAll('input, textarea, select').forEach(el => el.disabled = false);
          target.classList.remove('d-none');
        });
      } else {
        targets.forEach((target) => {
          target.querySelectorAll('input, textarea, select').forEach(el => el.disabled = true);
          target.classList.add('d-none');
        });
      }
    };
    if(checkbox.type === 'radio') {
      // radio buttons needs listeners added to their sibling inputs as well,
      // as they can be de-selected when a sibling is chosen
      document.querySelectorAll(`[name="${checkbox.name}"]`).forEach((radio) => {
        radio.addEventListener('change', update);
      });
    } else {
      checkbox.addEventListener('change', update);
    }
    update();
  });

  document.querySelectorAll('[data-checkbox-hide]').forEach((checkbox) => {
    const selector = checkbox.dataset.checkboxHide;
    const targets = document.querySelectorAll(selector);
    if(!targets.length) return;
    const update = () => {
      if (checkbox.checked) {
        targets.forEach((target) => {
          target.querySelectorAll('input, textarea, select').forEach(el => el.disabled = true);
          target.classList.add('d-none');
        });
      } else {
        targets.forEach((target) => {
          target.querySelectorAll('input, textarea, select').forEach(el => el.disabled = false);
          target.classList.remove('d-none');
        });
      }
    };
    if(checkbox.type === 'radio') {
      // radio buttons needs listeners added to their sibling inputs as well,
      // as they can be de-selected when a sibling is chosen
      document.querySelectorAll(`[name="${checkbox.name}"]`).forEach((radio) => {
        radio.addEventListener('change', update);
      });
    } else {
      checkbox.addEventListener('change', update);
    }
    update();
  });
});
