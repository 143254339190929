import React from 'react';
import { RadioGroup } from '@/components/Radio/Group';
import { RadioItem } from '@/components/Radio/Item';

interface StripeCardSelectorProps {
  value: string;
  onChange: () => void;
}

export function StripeCardSelector({ value, payment_methods, onChange }: StripeCardSelectorProps) {
  if (payment_methods.length === 0) return null;

  const renderCards = () => {
    return payment_methods.map((card) => {
      return (
        <RadioItem
          key={card.id}
          value={card.id}
          checked={card.id === value}
        >
          {`${card.data.brand} ending in ${card.data.last4}`}
        </RadioItem>
      );
    });
  };

  return (
    <RadioGroup name="method" value={value} onChange={(v) => onChange(v)}>
      {renderCards()}
      <RadioItem value="new">Use another card</RadioItem>
    </RadioGroup>
  );
}
