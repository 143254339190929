import React, { useState } from 'react';
import { User, Conversation, ConversationId } from '@/types';
import { ConversationPreview } from '@/components/Conversation/Preview';
import { ConversationChat } from '@/components/Conversation/Chat';
import { Pagination } from '@/components/Pagination';
import { useConversations } from './hooks';
import { useSelectedConversation } from '@/components/pages/inbox/hooks/useSelectedConversation';
import { getIdQueryParam } from '@/components/helpers/queryParam';
import classNames from 'classnames';

interface InboxPageProps {
  currentUser: User;
  getConversationsEndpoint: string;
  getConversationEndpoint: string;
  getMessagesEndpoint: string;
  sendMessageEndpoint: string;
  archiveConversationEndpoint: string;
  unarchiveConversationEndpoint: string;
}

function InboxPage({
  currentUser,
  getConversationsEndpoint,
  getConversationEndpoint,
  getMessagesEndpoint,
  sendMessageEndpoint,
  archiveConversationEndpoint,
  unarchiveConversationEndpoint,
}: InboxPageProps) {

  const {
    conversations,
    metadata,
    params,
    setParams,
    subjectList,
    loading,
    setConversations,
    archive,
    unarchive,
  } = useConversations({
    archiveConversationEndpoint,
    unarchiveConversationEndpoint,
    getConversationsEndpoint,
    currentUser,
  });

  const { selectedConversationId, setSelectedConversationId } = useSelectedConversation({
    initialConversationId: getIdQueryParam<ConversationId>('conversation_id'),
    getConversationEndpoint,
    conversations,
  });

  const back = () => {
    setSelectedConversationId(undefined);
    setParams((p) => ({ ...p })); // refresh
  };

  const onArchive = async () => {
    if (!selectedConversationId) return;
    await archive(selectedConversationId);
    setSelectedConversationId(undefined);
    if(params.subject_id && params.subject_type && conversations.length === 1) {
      // likely the last conversation on that subject, reset the subject filter
      setParams((p) => ({ ...p, subject_id: undefined, subject_type: undefined }));
    } else {
      setParams((p) => ({ ...p })); // refresh
    }
  }

  const onUnarchive = async () => {
    if (!selectedConversationId) return;
    await unarchive(selectedConversationId);
    setSelectedConversationId(undefined);
    if(params.subject_id && params.subject_type && conversations.length === 1) {
      // likely the last conversation on that subject, reset the subject filter
      setParams((p) => ({ ...p, subject_id: undefined, subject_type: undefined }));
    } else {
      setParams((p) => ({ ...p })); // refresh
    }
  }

  const highlight = ({type, id}) => {
    return params.subject_type === type && params.subject_id === id;
  };

  const showInbox = () => {
    setTempSearch('');
    setParams({search: '', archived: false, page: 1, subject_id: undefined, subject_type: undefined});
  };

  const showArchive = () => {
    setTempSearch('');
    setParams({search: '', archived: true, page: 1, subject_id: undefined, subject_type: undefined});
  };

  const search = () => {
    setParams((p) => ({ ...p, search: tempSearch, page: 1, subject_type: undefined, subject_id: undefined}))
  };

  const [tempSearch, setTempSearch] = useState('');

  return (
    <div>
      {!selectedConversationId && (
        <div className='w-100'>
          <div className='page-header'>
            <h2>
              { params.archived ? 'Archived conversations ' : 'Inbox ' }
              { loading && <div className="spinner-border" style={{borderWidth: '0.15em'}} role="status"><span className="visually-hidden">Loading...</span></div> }
            </h2>
          </div>
          <div className='row'>
            <div className='col-sm-4 col-md-3'>
              <div className='list-group mb-3'>
                <button className={classNames('list-group-item p-2 p-sm-3 text-start', (!params.search.length && !params.archived) ? 'bg-dark text-white' : '')}
                  onClick={showInbox}>
                  Inbox ({metadata.total})
                </button>
                <button className={classNames('list-group-item p-2 p-sm-3 text-start', (!params.search.length && params.archived) ? 'bg-dark text-white' : '')}
                  onClick={showArchive}>
                  Archive ({metadata.archived_total})
                </button>
              </div>

              <div className='d-none d-sm-block'>
                <p><small className='text-muted'>FILTER</small></p>
                <div className='list-group'>
                  <button
                    className={classNames('list-group-item p-2 text-start', highlight({type: undefined, id: undefined}) ? 'bg-primary text-white' : '')}
                    onClick={() => setParams((p) => ({...p, subject_id: undefined, subject_type: undefined, page: 1}))}>
                    Show all conversations
                  </button>
                  {subjectList.map((item) => (
                    <button key={item.key}
                      className={classNames('list-group-item p-2 text-start', highlight({type: item.type, id: item.id}) ? 'bg-primary text-white' : '')}
                      onClick={() => setParams((p) => ({...p, subject_id: item.id, subject_type: item.type, page: 1}))}>
                      {item.title} ({item.count})
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className='col-sm-8 col-md-9'>
              <div className='row mb-3 g-1'>
                <div className='col mb-1'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Search'
                    value={tempSearch}
                    onChange={(e) => setTempSearch(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') search();
                    }}
                  />
                </div>
                <div className='col-sm-auto'>
                  <button className='btn btn-success' onClick={search}>Search</button>
                  <button className='btn btn-secondary ms-1' onClick={showInbox}>Clear</button>
                </div>
              </div>

              <Pagination metadata={metadata} setParams={setParams} />

              {conversations.map((conversation) => (
                <ConversationPreview
                  key={conversation.id}
                  conversation={conversation}
                  onClick={() => setSelectedConversationId(conversation.id)}
                />
              ))}

              {conversations.length === 0 && <>No conversations to show</>}

              <Pagination metadata={metadata} setParams={setParams} />
            </div>
          </div>
        </div>
      )}

      {selectedConversationId && (
        <ConversationChat
          conversationId={selectedConversationId}
          currentUser={currentUser}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onBack={back}
          getConversationEndpoint={getConversationEndpoint}
          sendMessageEndpoint={sendMessageEndpoint}
        />
      )}
    </div>
  );
}

export default InboxPage;
