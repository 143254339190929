import React, { ReactNode } from 'react';

interface CheckboxProps {
  name: string;
  value?: any;
  checked: boolean;
  children: ReactNode;
  onChange: () => void;
}

export function Checkbox({ name, value, checked, children, onChange }: CheckboxProps) {
  return (
    <div className="checkbox">
      <label>
        <input type="checkbox" name={name} value={value} checked={checked} onChange={onChange} /> {children}
      </label>
    </div>
  );
}
