import React from 'react';
import { Money } from '@/components/Money';

interface StripeFormSubmitProps {
  processing: boolean;
  amountCents: number;
  disabled?: boolean;
}

export function StripeFormSubmit({ processing, amountCents, disabled }: StripeFormSubmitProps) {
  return (
    <button
      className='btn btn-block btn-success'
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? (
        <>Processing...</>
      ) : amountCents === 0 ? (
        <>Confirm</>
      ) : (
        <>
          Pay <Money cents={amountCents} />
        </>
      )}
    </button>
  );
}
