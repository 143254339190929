import React, { ReactNode } from 'react';
import { RadioContext } from '../RadioContext';

interface RadioGroupProps {
  name: string;
  value: any;
  onChange: (value: any) => void;
  children: ReactNode;
}

export function RadioGroup({ name, value, onChange, children }: RadioGroupProps) {
  return (
    <RadioContext.Provider value={{ value, select: onChange }}>
      <div className="radio">
        <input type="hidden" name={name} value={value} />
        {children}
      </div>
    </RadioContext.Provider>
  );
}
