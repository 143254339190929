import React from 'react';

interface PaginationProps {
  metadata: {
    page: number;
    pages: number;
    count: number;
    from: number;
    to: number;
    series: number[];
    prev: number | null;
    next: number | null;
  };
  setParams: (params: object) => void;
  anotehrProp: string;
}

export function Pagination({ metadata, setParams }: PaginationProps) {
  let gapCounter = 0;

  if(metadata && metadata.series) {
    metadata.series = metadata.series.map((n) => {
      if (n === 'gap') {
        gapCounter++;
        return `gap-${gapCounter++}`;
      } else {
        return n;
      }
    });
  }

  if (metadata && metadata.pages > 1) {
    return (
      <div>
        <nav className="pagy-bootstrap-nav" aria-label="pager">
          <ul className="pagination">
            <li className={`page-item prev ${metadata.prev || 'disabled'}`}>
              <button onClick={() => setParams((p) => ({ ...p, page: metadata.prev || 1}))} className="page-link p-2">‹&nbsp;Prev</button>
            </li>
            {metadata.series.map((n) => (
              n.toString().includes('gap') ? (
                <li key={n} className="page-item gap disabled"><span className="page-link p-2 px-1">…</span></li>
              ) : (
                <li key={n} className={`page-item ${metadata.page.toString() === n.toString() ? 'active' : ''}`}>
                  <button onClick={() => setParams((p) => ({ ...p, page: n}))} className="page-link p-2">{n}</button>
                </li>
              )
            ))}
            <li className={`page-item next ${metadata.next || 'disabled'}`}>
              <button onClick={() => setParams((p) => ({ ...p, page: metadata.next}))} className="page-link p-2">Next&nbsp;›</button>
            </li>
          </ul>
        </nav>
        <p>Showing {metadata.from} - {metadata.to} of {metadata.count} conversations.</p>
      </div>
    );
  } else {
    return null;
  }
}
